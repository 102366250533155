import { Platform } from 'react-native';
import { SignInEventProperties } from '../types';
import { track } from '../index';
export const EVENTS = {
    LOGIN_SUCCESS: 'Login Success',
    LOGIN_ERROR: 'Login Error'
   } as const;

export const trackSignIn = async (
    success: boolean,
    method: SignInEventProperties['method'],
    options?: {
      demo?: boolean;
      error?: string;
    }
  ): Promise<void> => {
    try {
      const baseProperties: Partial<SignInEventProperties> = {
        method,
        success,
        platform: Platform.OS,
        timestamp: new Date().toISOString(),
        ...(options?.demo !== undefined && { demo: options.demo })
      };
  
      if (success) {
        await track<SignInEventProperties>(EVENTS.LOGIN_SUCCESS, baseProperties);
      } else {
        await track<SignInEventProperties>(EVENTS.LOGIN_ERROR, {
          ...baseProperties,
          error: options?.error || 'Unknown error'
        });
      }
    } catch (error) {
      console.error('🔴 Error tracking sign in:', error);
    }
  };