import { Platform } from 'react-native';
import { UserProperties } from '../types';
import { mixpanel, } from '../index';

export const identifyUser = async (
    userId: string, 
    userProperties: Partial<UserProperties>
  ): Promise<void> => {
    try {
      await mixpanel.identify(userId);
      
      const properties: UserProperties = {
        ...userProperties,
        user_id: userId,
        platform: Platform.OS,
        last_login: new Date().toISOString(),
      };
  
      mixpanel.getPeople().set(properties);
      mixpanel.flush();
    } catch (error) {
      console.error('🔴 Error identifying user:', error);
    }
  };
  
 