import { NavigationState, PartialState } from '@react-navigation/native';
import { Platform } from 'react-native';
import { track } from '..';

// Important screens to track
const IMPORTANT_SCREENS = [
  'ChildCategory',
  'ClassCategory', 
  'Child',
  "ChildOverview",
  'Class',
  "ClassStats",
  'CreateAssessment'
];

// Track 70% of navigation events
const SAMPLING_RATE = 0.7;
const shouldTrack = () => Math.random() < SAMPLING_RATE;

interface ScreenViewProperties {
  screen_name: string;
  previous_screen?: string;
  navigation_type: string;
  platform: typeof Platform.OS;
  timestamp: string;
  session_id?: string;
  entered_at: string;
  user_type?: string;
  screen_load_time?: number;
  is_first_visit?: boolean;
  navigation_method?: 'tap' | 'swipe' | 'button' | 'system_back';
  screen_category?: string;
  interaction_depth?: number;
  params?: Record<string, any>;
  error?: {
    type: string;
    message: string;
  };
}

let previousScreen: string | undefined;
let screenVisitCounts: Record<string, number> = {};
let sessionStartTime = new Date().toISOString();
let navigationDepth = 0;


let screenEntryTimes: Record<string, string> = {};

export const useNavigationTracking = () => {
  const onStateChange = async (
    state: NavigationState | PartialState<NavigationState> | undefined
  ) => {
    if (!state || !shouldTrack()) return;
    
    try {
      const currentRoute = state.routes[state.index || 0];
      if (!currentRoute) return;


      // Calculate time spent on previous screen
      if (IMPORTANT_SCREENS.includes(currentRoute.name)) {
        if (previousScreen) {
          const entryTimeStr = screenEntryTimes[previousScreen];
          if (entryTimeStr) { 
            const entryTime = new Date(entryTimeStr).getTime();
            const exitTime = new Date().getTime();
            const durationSeconds = (exitTime - entryTime) / 1000;

            if (durationSeconds > 1) {
              await track(previousScreen + " left", {
                screen_name: previousScreen,
                duration_seconds: Math.round(durationSeconds),
                timestamp: new Date().toISOString(),
                platform: Platform.OS
              });
            }
          }
        }
      }

      if (IMPORTANT_SCREENS.includes(currentRoute.name)) {
        screenVisitCounts[currentRoute.name] = (screenVisitCounts[currentRoute.name] || 0) + 1;
        navigationDepth++;

        screenEntryTimes[currentRoute.name] = new Date().toISOString();
        await track<ScreenViewProperties>(currentRoute.name, {
          screen_name: currentRoute.name,
          previous_screen: previousScreen,
          navigation_type: state.type || 'stack',
          entered_at: new Date().toISOString(),
          platform: Platform.OS,
          timestamp: new Date().toISOString(),
          session_id: sessionStartTime,
          screen_category: currentRoute.name,
          is_first_visit: screenVisitCounts[currentRoute.name] === 1,
          interaction_depth: navigationDepth,
          ...(currentRoute.params && { 
            params: {
              ...currentRoute.params,
              password: undefined,
              token: undefined
            }
          })
        });
      }

      previousScreen = currentRoute.name;
    } catch (error) {
      console.error('🔴 Navigation tracking error:', error);
      await track('Navigation Error', {
        screen_name: state.routes[state.index || 0]?.name,
        error_type: error instanceof Error ? error.name : 'Unknown',
        error_message: error instanceof Error ? error.message : 'Unknown error',
        timestamp: new Date().toISOString()
      });
    }
  };

  return { onStateChange };
};